document.addEventListener('DOMContentLoaded', function () {
    const menuButton = document.querySelector('[aria-controls="mobile-menu"]');
    const menu = document.getElementById('mobile-menu');

    menuButton.addEventListener('click', () => {
        const expanded = menuButton.getAttribute('aria-expanded') === 'true' || false;
        menuButton.setAttribute('aria-expanded', !expanded);
        menu.classList.toggle('hidden');
    });
});
